@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

.bg-light {
  @apply bg-gray-200;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f3f4f6' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.bg-dark {
  @apply bg-gray-900;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231f2937' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.text-link {
  @apply underline underline-offset-1 decoration-primary-500 dark:decoration-primary-400 decoration-2 hov:hover:decoration-primary-300 dark:hov:hover:decoration-primary-300;
}

.pill {
  @apply text-sm rounded-full py-0.5 px-2;
}

.text-muted {
  @apply text-gray-600 dark:text-gray-300;
}

.default-text {
  @apply text-black dark:text-white;
}

.gray-black-bg {
  @apply bg-gray-200 dark:bg-gray-800;
}

.basic-card {
  @apply text-black bg-white dark:bg-gray-700 dark:text-white transition-colors;
}

.cert-item {
  @apply text-black bg-gray-100 dark:bg-gray-800 dark:text-white hov:hover:bg-gray-200 dark:hov:hover:bg-gray-900 transition-colors;
}

.bordered-item {
  @apply border-gray-200 border bg-white text-black dark:bg-gray-800 dark:border-gray-600 dark:text-white transition-colors;
}

.text-primary {
  @apply text-primary-500 dark:text-primary-400 transition-colors;
}

.text-secondary {
  @apply text-secondary-500 dark:text-secondary-400 transition-colors;
}

.bullets {
  @apply list-outside list-disc pl-5;
}
